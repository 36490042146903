import React from "react"
import "./404.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="section notfound__section">
      <div className="container has-text-centered">
        <h1 className="title">NOT FOUND</h1>
        <p className="subtitle">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
